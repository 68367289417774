<template lang="pug">
.page
  el-card
    el-form
      el-form-item()
        el-radio-group(v-model="form.type" @change="onTypeChange")
          el-radio-button(v-for="i in descTypeList" :label="i.value") {{i.name}}
      el-form-item()
        comEditor(v-model="form.desc" height="600")
      el-form-item()
        el-button(type="primary" :disabled="!!validateErrorMessage" style="width: 100%;" @click="handleSubmit" :loading="loading") {{validateErrorMessage||'保存'}}

</template>

<script setup>
import { inject, onMounted, computed, ref } from 'vue'

import comEditor from '@/component/editor/index.vue'

import { currentMarketId } from '@/provider/account'
import { getMockData, updateMockData } from '@/provider/mock'

const { fetch, message, router } = inject('global')

const descTypeList = ref([
  { name: '收费', value: 'parkingChargeDetail' },
  { name: '设施', value: 'parkingFacilitiesDetail' }
])
const loading = ref(false)
const form = ref({
  type: 'parkingChargeDetail',
  desc: ''
})
const raw = ref({})
const validateErrorMessage = computed(() => {
  if (!form.value.desc) return '详情不能为空'
  if (form.value.desc.length < 24) return '详情不能少于24个字'
  return ''
})

function onTypeChange() {
  form.value.desc = ''
  getData()
}

function handleSubmit() {
  if (validateErrorMessage.value) {
    message.warning(validateErrorMessage.value)
  } else {
    loading.value = true
    fetch
      .post(
        `/boom-center-product-service/businessAdmin/commercialPlazaAssistant/facilitiesEdit/${currentMarketId.value}`,
        {
          ...raw.value,
          [form.value.type]: form.value.desc,
          commercialPlazaId: currentMarketId.value
        }
      )
      .then(res => {
        loading.value = false
        message.success('保存成功，等待审核')
        router.go(-1)
      })
      .catch(err => {
        loading.value = false
        message.error(err?.msg || err)
      })
  }
}

function getData() {
  if (!form.value.type) {
    return message.success('请先选择内容类型')
  }
  fetch
    .get(`/boom-center-product-service/sysAdmin/commercialPlaza/facilities/${currentMarketId.value}`)
    .then(res => {
      raw.value = res
      form.value.desc = res[form.value.type] || ''
    })
    .catch(err => {
      message.error(err?.msg || err)
    })
}

onMounted(() => {
  getData()
})
</script>

<style lang="less" scoped>
.page {
  display: flex;
  justify-content: center;
  .el-card {
    max-width: 900px;
  }
}
</style>
